import React, { useState, useEffect, useContext } from 'react'

import PayPal from './PayPal'
import Paymentwall from './Paymentwall'
import WesternUnion from './WesternUnion'
import Cripto from './Cripto'
import { useTranslation } from 'react-i18next';

export default function SelectPaymentMethod(props) {

    const { user,uid, activeDevice, product, reseller } = props

    const { t } = useTranslation();


    useEffect(() => {
        console.log(props)
    }, [])

    if (!user || !product) return null

    return (
        <div className='bg-light px-2 py-2 rounded'>
            
            <div className='text-center'><h6 className='mt-1 text-dark'>{t('SelectPaymentMethod.select')}</h6></div>

            <PayPal user={user} uid={uid} product={product} activeDevice={activeDevice} reseller={reseller} />
            <Paymentwall user={user} uid={uid} product={product} activeDevice={activeDevice} reseller={reseller} />
            <WesternUnion user={user} uid={uid} product={product} activeDevice={activeDevice} reseller={reseller} />
            <Cripto user={user} product={product} activeDevice={activeDevice} reseller={reseller} />
        </div>
    )

}
