import React, { useState, useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { use } from "react";
import { useTranslation } from 'react-i18next';
import Confetti from 'react-confetti'


export default function PayPal(props) {

    const { user, uid, activeDevice, product, reseller } = props


    useEffect(() => {
        // window.$('#paymentDoneModal').modal();
        // console.log(activeDevice)
    }, [])




    const initialOptions = {
        clientId: "AfQ8WVojU4uODIfCc-W2IbLQRQREDZ6PaXAFu-BfpDiB8aWysTmvVGJ74wkXOpS_iakfV41Ts4_BcCyG",
        // components: "buttons,marks,funding-eligibility",
        currency: "USD",
        intent: "capture",
    };

    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [{
                amount: {
                    value: product.price,
                    currency_code: "USD",
                },
                custom_id: uid,
                description: `${product.productId}`,
            },],
            application_context: {
                shipping_preference: "NO_SHIPPING", // Evita pedir dirección de envío
                brand_name: "CatWatchful",
                user_action: "PAY_NOW"
            },
            payer: {
                address: {
                    country_code: activeDevice?.infoCel?.country || "US",
                },
            },
        });
    };

    const onApprove = (data, actions) => {
        window.$('#paymentDoneModal').modal();

        return actions.order.capture().then((details) => {
            console.log("onApprove", details)
            // alert(`Pago completado por ${details.payer.name.given_name}`);
        });
    };
    const onCancel = (data) => {
        console.log("onCancel", data)
        console.log(data)
    }


    const onError = (err) => {
        console.log("onError", err)
        console.log(err)
    }

    const onClick = (data, actions) => {
        if (data.fundingSource === "card") {
            console.log("card")
        } else {
            console.log("paypal")
        }
    }





    const PayPalButtonWithLoading = () => {
        const [{ isPending }] = usePayPalScriptReducer();
        return (
            <div>
                {isPending ? (
                    <div className="text-dark text-center"><i className="fa fa-spinner fa-spin mr-1 fa-2x mt-2" />Loading...</div>
                ) : (
                    <PayPalButtons
                        style={{ height: 40 }}
                        createOrder={createOrder}
                        onApprove={onApprove}
                        onError={onError}
                        onCancel={onCancel}
                        onClick={onClick}
                    />
                )}
            </div>
        );
    };

    return (
        <div className="mb-4" style={{ maxWidth: "500px", margin: "auto" }}>
            <PaymentDonePromt />

            <PayPalScriptProvider options={initialOptions}>
                <PayPalButtonWithLoading />
            </PayPalScriptProvider>
        </div>
    );



    function PaymentDonePromt() {

        const { t } = useTranslation();

        const onModalClick = () => {
            window.location.href = '/';
        }

        return (
            <div className="modal fade text-dark" id="paymentDoneModal" tabIndex="-1" role="dialog" aria-labelledby="paymentDoneModal" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">

                            <div className="d-flex" style={{ alignItems: 'center' }}>
                                <i className="fa fa-check-circle fa-2x text-success mr-2" />
                                <h5 className="modal-title" id="paymentDoneModal">{t("PayPal.paymentDone")}</h5>
                            </div>

                            <Confetti
                                width={460}
                                height={300}
                                recycle={false}
                            />
                            {/* 
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button> */}

                        </div>
                        <div className="modal-body text-left small" >
                            <div> {t("PayPal.txt1", {name:product.name})} </div>
                            <div>{t("PayPal.txt2")} </div>
                            <div className="mt-2">{t("PayPal.txt3")}</div>


                        </div>
                        <div className="modal-footer">
                            <button onClick={onModalClick} type="button" className="btn btn-primary" data-dismiss="modal" >{t("PayPal.btnContinue")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}