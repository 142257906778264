import React, { useState, useEffect, useContext } from 'react'
import axios from "axios";

import { useTranslation } from 'react-i18next';

const PAYMENT_REDIRECT_URL = "https://catwatchful.pink/webservice/payment_redirect.php"


export default function Paymentwall(props) {

    const { user, uid, activeDevice, product, reseller } = props

    const [loadingBuyButton, setLoadingBuyButton] = useState(false)

    const { t } = useTranslation();


    useEffect(() => {

        return () => {
            setLoadingBuyButton(false)
        }
    }, [])



    async function buttonClick() {

        setLoadingBuyButton(true)

        let requestPayUrl = PAYMENT_REDIRECT_URL + `?uid=${uid}
                    &email=${user.email}
                    &product_opt=${product.productId}
                    &reseller=${reseller ? `${reseller.resellerId}-${reseller.referCode}` : '0'}`;

        console.log("requestPayUrl", requestPayUrl)
        // setLoadingBuyButton(true)
        await axios({
            method: 'GET',
            url: requestPayUrl,
            dataType: 'json',
        }).then(response => {
            //SE OBTUVO LA URL DE PAGO!  :D
            console.log(response)
            window.location.href = response.data
        }).catch(e => {
            console.log("Error", e)
        }).finally(() => {
            setLoadingBuyButton(false)
        });








    }

    return (
        <div className='mt-1' style={{ maxWidth: "500px", margin: "auto" }}>
            {loadingBuyButton ?
                <button className='btn btn-outline-dark d-block w-100' ><i className='fa fa-spinner fa-spin' /></button>
                :
                <button className='btn btn-outline-dark d-block w-100' onClick={buttonClick}><i className='fa fa-globe' /> {t('Paymentwall.btnLocal')}</button>
            }
        </div>
    )
}
