import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next';

export default function WesternUnion(props) {

    const { user, activeDevice, product, reseller } = props

    useEffect(() => {
        // window.$('#westernUnionModal').modal();
    }, [])



    async function buttonClick() {
        window.$('#westernUnionModal').modal();
    }

    return (
        <div className='mt-1 ' style={{ maxWidth: "500px", margin: "auto" }}>
            <WesternUnionPrompInfo />

            <button className='btn btn-outline-dark d-block w-100' onClick={buttonClick}><img src="images/westernlogo.png" alt="logo" style={{ width: "30px" }} /> Western Union</button>
        </div>
    )



    function WesternUnionPrompInfo() {

        const { t } = useTranslation();

        const onModalClick = () => {
            // window.location.href = '/';
        }

        return (
            <div className="modal fade text-dark" id="westernUnionModal" tabIndex="-1" role="dialog" aria-labelledby="westernUnionModal" aria-hidden="true">



                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">

                            <div className="d-flex" style={{ alignItems: 'center' }}>
                                {/* SVG LOGO */}

                                <img src="images/westernlogo.png" alt="logo" />
                                <span className='ml-2'>{t("WesternUnion.title")}</span>
                            </div>

                            {/* 
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button> */}

                        </div>
                        <div className="modal-body text-left small" >
                            {/* <div><b>Aceptamos pagos a través de Western Union </b></div> */}
                            <div className=''>

                                {t("WesternUnion.txt1")}
                                <a href="mailto:admin@catwatchful.com" className="text-blue-600 hover:underline"> admin@catwatchful.com</a>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button onClick={onModalClick} type="button" className="btn btn-primary" data-dismiss="modal" >{t("WesternUnion.btnAceptar")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
