import React, { useState, useEffect, useContext } from 'react'

export default function Cripto(props) {

    const { user, activeDevice, product, reseller } = props

    useEffect(() => {

    }, [])



    async function buttonClick() {

    }

    return (
        null
        // <div className='mt-1 d-flex justify-content-center mt-2' >
        //     <button className='btn btn-outline-dark d-block w-100' onClick={buttonClick}><i className='fa fa-bitcoin fa-brands'/> Bitcoin</button>
        //     {/* <form action="https://bitpay.com/checkout" method="post">
        //         <input type="hidden" name="action" value="checkout" />
        //         <input type="hidden" name="posData" value="" />
        //         <input type="hidden" name="notificationType" value="json" />
        //         <input type="hidden" name="data" value="PBNq9CI6rcy9ckyV5eNoS7IC1nMCrfFb2/l0NoTqXh1Nd5r0CEFuUi2PNk0BV4NvbJC2q38OKTvJFdtFjiAHYm5hB9FdQz/wE8Ejk3gWnYzKm3A9qc1S+X+S3L8ZgAb4C6rqufcXkt+Dth+gRvqwGQ==" />
        //         <input type="image" src="https://bitpay.com/cdn/en_US/bp-btn-pay-currencies.svg" name="submit" style={{width: "210px"}} alt="BitPay, the easy way to pay with crypto." />
        //     </form> */}


        // </div>
    )
}
